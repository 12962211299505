<template>
  <ul class="elevator_list" v-show="!flag">
    <li><div class="link" @click="jumpMyOrder">我的订单</div></li>
    <li><div class="link" @click="jumpMyBalance">我的余额</div></li>
    <li>
      <el-popover
        trigger="hover"
        placement="left"
        width="160"
        ref="customer_service"
      >
        <el-col :span="24">
          <div class="customerBox">
            <div class="left_box">
              <el-avatar :size="20" icon="el-icon-phone-outline"></el-avatar>
            </div>
            <div class="right_box">
              <p>专属客服{{ info.customName }}</p>
              <p>{{ info.customPhone }}</p>
            </div>
          </div>

          <div class="customerBox">
            <div class="left_box">
              <el-avatar :size="20" icon="el-icon-phone-outline"></el-avatar>
            </div>
            <div class="right_box">
              <p>平台客服热线</p>
              <p>4001008189</p>
            </div>
          </div>
        </el-col>
      </el-popover>
      <span
        class="serviceBtn"
        @mouseenter="GetName()"
        v-popover:customer_service
        >联系客服</span
      >
    </li>
    <!-- <li><div class="link" @click="jumpSmartSourcing">智能采购</div></li> -->
    <li class="QRcode">
      <img src="@/assets/3AApp_down.png" alt="">
      <a>扫码二维码下载</a>
    </li>
    <li>
      <el-backtop>
         <div style="
         ">
           <i class="el-icon-top" style="font-size:14px;font-weight:bold;"></i>
           <span style="font-size:15px;">返回顶部</span>
          </div>
      </el-backtop>
    </li>
  </ul>
</template>
<style scoped lang="less">
@import "~style/index.less";
.customerBox:last-child {
  margin-bottom: 0;
}
.customerBox {
  height: 36px;
  margin-bottom: 5px;
  .left_box {
    float: left;
    padding: 6px;
    .el-avatar {
      background: #FFD303 ;
    }
  }
  .right_box {
    float: left;
    p {
      line-height: 17px;
      font-size: 12px;
      color: #333333;
    }
  }
}
.elevator_list {
  margin-bottom: 56px;
  position: fixed;
  left: 50% !important;
  margin-left: 615px;
  top: 50% !important;
  margin-top: -90px;
  cursor: pointer;
  z-index: 5;
  li {
    width: 94px;
    background: #fff;
    color: #666666;
    text-align: center;
    font-size: 13px;
    margin-bottom: 2px;
    border: 1px solid #F2F2F2;
    .serviceBtn {
      width:64px;
      height:20px;
      padding: 10px 15px;
      text-align: center;
      font-size: 13px;
      display: block;
    }
    .link {
      width:64px;
      height:20px;
      padding: 10px 15px;
      text-align: center;
      font-size: 13px;
      display: block;
    }
  }
  .QRcode{
    width: 94px;
    margin-top: 10px;
    padding-bottom: 10px;
    img{
      padding-top: 10px;
      width:74px;
      height:74px;
    }
    a{
      width: 100%;
      display: inline-block;
      font-size: 12px;
      color: #333;
      line-height: 14px;
      font-weight: 600;
    }
  }
  li:hover {
    background: #FFD303;
    color: #000;
    a {
      color: #000;
    }
  }
}
.el-backtop {
  width: 94px;
  height:36px;
  padding-bottom: 5px;
  border-radius: 0%;
  left: 50% !important;
  margin-left: 615px;
  top: 50% !important;
  // margin-top: 90px !important;
   margin-top:160px!important;
  color: #FFD303 ;
}
.el-backtop:hover {
  background: #FFD303;
  color: #000;
}

@media screen and (max-width: 1280px) {
  .el-backtop {
    margin-left: 585px;
  }
  .elevator_list {
    margin-left: 585px;
  }
}
</style>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      info: {},
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  computed: {
    //计算属性可实时更新购物车数量
    ...mapState({
      flag: (state) => state.flag,
    }),
  },
  created() {
    // if(!this.flag){
    //   this.$set(this.info, "customName",localStorage.getItem("customName"));
    //   this.$set(this.info, "customPhone",localStorage.getItem("customPhone"));
    // }
  },
  methods: {
    GetName() {
      this.$set(this.info, "customName", localStorage.getItem("customName"));
      this.$set(this.info, "customPhone", localStorage.getItem("customPhone"));
    },
    jumpMyBalance() {
      // this.$router.push({ name: "Coupon" });
       this.$router.push({ name: "Balance" });
    },
    jumpMyOrder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpSmartSourcing() {
      this.$router.push({ name: "SmartSourcing" });
    },
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
  },
};
</script>
